import styles from "./../../styles/contains.module.css";
import { motion } from "framer-motion";
import Item from "./Item";

export default function Contains() {
  const containsData = [
    {
      title: "Resource Mapping",
      paragraph:
        "From Recovery Coaches to Therapists and more, iFreeThink brings the available resources to you!",
      delay: 0.4,
      duration: 0.5,
    },
    {
      title: "Self Determined Treatment Plan",
      paragraph:
        "Your Treatment plan is up to you! Everyone has different requirements and goals, and our software acknowledges that, allowing you to pick the next step and define your own goals.",
      delay: 0.7,
      duration: 0.6,
    },
    {
      title: "Integrated Calendar",
      paragraph:
        "All of your Recovery related events and appointments in one place. Not only can you set your own schedule, but if you are working with a Peer Recovery Coach, they can add items to make sure you stay on track!",
      delay: 0.3,
      duration: 0.5,
    },
    {
      title: "Peer To Peer",
      paragraph:
        "Meet with your therapist, recovery coach or a sponsor from the user base.",
      delay: 0.6,
      duration: 0.4,
    },
    {
      title: "Career Advancement",
      paragraph:
        "Find Job Listings, fill out applications, and mark your progress.",
      delay: 0.7,
      duration: 0.5,
    },
    {
      title: "Individualized Education",
      paragraph:
        "Educational content tailored to you! From recovery videos to resume tutorials, scroll the feed and learn how to get closer to your recovery and career goals.",
      delay: 0.4,
      duration: 0.5,
    },
  ];
  return (
    <div className={styles.containsContainer}>
      <h1 className={styles.containsHeading}>
        <span>INTEGRATED</span> RECOVERY
      </h1>
      <hr className={styles.outerHR} />
      <p className={styles.outerP}>
        <strong>iFreeThink&apos;s </strong>Recovery Assistance software suite is
        designed to fill in those hard to identify gaps. From appointment
        setting to peer to peer interactions and resource related educational
        content, our apps are designed to give you a distinct advantage. Whether
        you are a recovery coach or someone in the throes of addiction recovery
        themselves, our programs are designed to make your life easier, and to
        markedly increase the chances that your goals will be met.
      </p>
      <div className={styles.boxesContainer}>
        {containsData.map((item) => {
          return (
            <Item
              obj={{
                title: item.title,
                paragraph: item.paragraph,
                delay: item.delay,
                duration: item.duration,
              }}
              styles={styles}
            />
          );
        })}
      </div>
    </div>
  );
}
