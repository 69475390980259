import { useState } from "react";
import styles from "./../../styles/clientDashboard.module.css";

import SideNav from "./SideNav";
import ClientHome from "./ClientHome";
export default function ClientDashboard() {
  const [active, setActive] = useState(0);

  return (
    <div className={styles.container}>
      <SideNav styles={styles} changeActive={setActive} />
      {active == 0 && <ClientHome styles={styles} />}
    </div>
  );
}
