import { ThemeContext } from "../theme/ThemeContext";
import styles from "./../styles/contact.module.css";
import { useContext, useState } from "react";
import { validate } from "email-validator";
import { addContact } from "../lib/databaseFunctions";
import useTyping from "../useTyping";
export default function Contact() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const validCheck = () => {
    let valid = true;
    if (name === "" || name.length === 0) {
      console.log("name not valid : ", name);
      valid = false;
    }
    if (email === "" || email.length === 0 || !validate(email)) {
      console.log("email not valid : ", email);
      valid = false;
    }
    if (message === "" || message.length === 0) {
      console.log("message not valid : ", message);
      valid = false;
    }
    console.log("message length is ", message.length);
    return valid;
  };
  const submit = (e) => {
    e.preventDefault();
    let valid = validCheck();
    if (valid) {
      console.log("valid, name is: ", name);
      addContact({ name: name, message: message, email: email })
        .then(function (res) {
          setName("");
          setEmail("");
          setMessage("");
          setTimeout(setSending(false), 2000);
        })
        .catch(function (err) {
          console.log("error adding contact: ", err);
        });
      setSending(true);
    } else {
      console.log("not valid");
    }
  };
  return (
    <div id="contact" className={styles.contactContainer}>
      {/* <div className={styles.contactInfo}>
        <h1>Contact iFreeThink</h1>
        <p>info@ifreethink.com</p>
      </div> */}

      <form className={theme === "dark" ? styles.darkContainer : ""}>
        <h1>
          HIT <span className={styles.offset}>US</span> UP
        </h1>
        {/* <hr /> */}
        <p>info@ifreethink.com</p>
        <input
          className={styles.formInput}
          id="name"
          name="name"
          placeholder="name"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          className={styles.formInput}
          id="email"
          name="email"
          placeholder="email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <textarea
          className={styles.formInput}
          id="message"
          name="message"
          placeholder="message"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
        />
        {sending ? (
          <SendingObj />
        ) : (
          <button className={styles.submit} onClick={submit}>
            SUBMIT
          </button>
        )}
      </form>
    </div>
  );
}

function SendingObj() {
  const loading = useTyping(["SENDING..."]);
  return <h1>{loading}</h1>;
}
