import React, { useContext, useState } from "react";
import styles from "./../styles/footer.module.css";
import lightLogo from "./../assets/images/lightLogo.png";
import darkLogo from "./../assets/images/darkLogo.png";

import { InnerMoon } from "@theme-toggles/react";
import { ThemeContext } from "../theme/ThemeContext";

export default function Footer() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [toggled, setToggled] = useState(false);
  let src = lightLogo;
  if (theme === "dark") {
    src = darkLogo;
  }
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.logoContainer}>
        <img className={styles.footerLogo} src={src} width={813} height={182} />
        <div className={styles.themeToggle}>
          <p>Switch Theme to {theme === "dark" ? "Day" : "Night"}</p>
          <InnerMoon
            toggled={toggled}
            duration={1000}
            className={styles.themeToggle}
            toggle={() => {
              if (theme === "dark") {
                setTheme("light");
                setToggled(true);
              } else {
                setTheme("dark");
                setToggled(false);
              }
            }}
          />
        </div>
      </div>
      <div className={styles.footerText}>
        <p>iFreeThink is an llc this and that and this and copyright</p>
        <hr />
        <small>
          This site was developed by{" "}
          <a href="https://brettkozmadesigns.com/" target="_blank">
            BK Designs
          </a>
        </small>
      </div>
    </footer>
  );
}
