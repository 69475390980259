import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function LoginForm({ styles, submit, message }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sending, setSending] = useState(false);
  return (
    <form className={styles.registerForm} onSubmit={submit}>
      <h1>LOGIN VIA EMAIL</h1>
      <div className={styles.inputRow}>
        <div className={styles.inputSVGContainer}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <input placeholder="Email" type="email" name="email" />
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputSVGContainer}>
          <FontAwesomeIcon icon={faLock} />
        </div>
        <input placeholder="Password" type="password" name="password" />
      </div>
      {message !== null ? <p className={styles.errorMessage}>{message}</p> : ""}
      <button className={styles.submit} type="submit">
        SUBMIT
      </button>
    </form>
  );
}
