import { useState, useEffect } from "react";

import { auth, firestore } from "./firebase";

export default function useUserData() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState(null);
  console.log("inside useUserData");
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userData) => {
      setLoading(false);
      console.log("userData from auth: ", userData);
      if (userData) {
        console.log("setting userData");
        setUser((oldUser) => {
          return userData;
        });
      } else {
        console.log("no user, not setting data");
        setUser((oldUser) => {
          return null;
        });
      }
    });
    return () => unsubscribe();
  }, []);
  //   useEffect(() => {
  //     let unsubscribe;
  //     if (user) {
  //       console.log("have user in app: ", user);

  //       const ref = firestore.collection("users").doc(user.uid);
  //       unsubscribe = ref.onSnapshot((doc) => {
  //         setUserID(doc.data()?.uid);
  //       });
  //     } else {
  //       console.log("no user in app");
  //       setUserID(null);
  //     }
  //     return unsubscribe;
  //   }, [user]);
  if (loading) {
    return "loading";
  }
  return user;
}
