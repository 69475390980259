import {
  faDashboard,
  faPeopleArrows,
  faCity,
  faVideo,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useState } from "react";
import { logout } from "../../lib/authFunctions";
import { useAuth } from "../../lib/FirebaseProvider";
import logo from "./../../assets/images/darkLogo.png";
export default function SideNav({ styles }) {
  const [active, setActive] = useState(null);
  const user = useAuth();

  return (
    <div className={styles.sideNav}>
      <div className={styles.user}>
        <img src="/icon.png" style={{ width: "30px", height: "30px" }} />
        <p>{user.user.email}</p>
      </div>

      <ul>
        <li
          onClick={() => {
            setActive(0);
          }}
        >
          {active === 0 && (
            <ul
              initial={{ x: -200 }}
              animate={{ x: 0 }}
              className={styles.subMenu}
              transition={{ duration: 1, type: "spring" }}
            >
              <li>listitem1</li>
              <li>listitem2</li>
              <li>listitem1</li>
              <li>listitem2</li>
            </ul>
          )}

          <FontAwesomeIcon icon={faDashboard} />
          <h1>DASHBOARD</h1>
        </li>
        <li>
          {" "}
          <FontAwesomeIcon icon={faPeopleArrows} />
          <h1>MENTORS</h1>
        </li>
        <li>
          {" "}
          <FontAwesomeIcon icon={faCity} />
          <h1>RESOURCES</h1>
        </li>
        <li>
          {" "}
          <FontAwesomeIcon icon={faVideo} />
          <h1>FEED</h1>
        </li>
        <li
          onClick={() => {
            logout();
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faSignOut} />
          <h1>LOGOUT</h1>
        </li>
      </ul>
    </div>
  );
}
