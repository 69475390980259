import React from "react";
import styles from "./../styles/solvenow.module.css";

export default function SolveNow() {
  return (
    <div className={styles.solveNowContainer}>
      <h1>
        <span>SOLVE NOW</span> THINK LATER SOFTWARE ENGINEERING
      </h1>
      <hr />
      <p>
        The &quot;i Free Think&quot; platform aims to bridge gaps in public
        health communication, focusing on mental health and addiction
        rehabilitation. We stand out by providing a centralized hub for seamless
        communication among stakeholders like medical professionals and law
        enforcement, offering personalized, data-driven support while ensuring
        privacy and security.
      </p>
      <p>
        Compared to existing solutions, we consolidate information for
        efficiency, we customize to user needs and with prioritized data privacy
        in accordance with HIPAA. We focus on integration with services that
        exist currently today.
      </p>
      <p>
        It also enables impactful research into public health interventions,
        potentially contributing to evidence-based practices and policy
        recommendations. &quot;i Free Think&quot; addresses critical issues like
        information fragmentation and accessibility, we bring a new standard to
        public health communication and service delivery.
      </p>
    </div>
  );
}
