import styles from "./../styles/mockMentors.module.css";
import mentor1 from "./../assets/images/mentor1.jpg";
import mentor2 from "./../assets/images/mentor2.jpg";
import mentor3 from "./../assets/images/mentor3.jpg";
import { motion } from "framer-motion";
export default function MockMentors() {
  const mentors = [
    {
      name: "Laney Funke",
      city: "Birmingham, Virginia",
      bio: "Hi, My name is Laney, I am a certified therapist with 6 years of experience helping addicts transform their lives.",
      img: mentor1,
    },
    {
      name: "Tammy Shelling",
      city: "Goby, Missouri",
      bio: "Peer Recovery Coach. Life Coach. Mentor. I have twenty years of experience leading my clients toward success.",
      img: mentor2,
    },
    {
      name: "Mark Johnson",
      city: "Chicago, Illinois",
      bio: "I'm here to make a difference. When I was in recovery I was surrounded by great people, I'm trying to do that.",
      img: mentor3,
    },
  ];
  return (
    <div className={styles.container}>
      <h1>FEATURED MENTORS</h1>
      <hr />
      <p>
        Join iFREEThink today to have access to mentors from all over the world.
        We have people from every walk of life, with experience in all aspects
        of peer recovery.
      </p>
      <div className={styles.innerMentor}>
        {mentors.map((mentor, index) => {
          console.log("inside map, index is: ", index);
          return <Mentor info={mentor} index={index} />;
        })}
      </div>
    </div>
  );
}
function Mentor({ info, index }) {
  console.log("inside mentor: ", index);
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.5, type: "spring" }}
      viewport={{ once: true }}
      className={styles.mentor}
    >
      <img src={info.img} />
      <div className={styles.mentorInfo}>
        <h1>{info.name}</h1>
        <hr />
        <small>{info.city}</small>
        <p>{info.bio}</p>
        <a href="/mentors/example" className={styles.mentorLink}>
          View My Bio
        </a>
      </div>
    </motion.div>
  );
}
