import { useEffect, useState } from "react";
import styles from "./../styles/register.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LoginForm from "../components/login/Login";
import { login, loginWithGoogle } from "../lib/authFunctions";
import { useNavigate } from "react-router-dom";
import google from "./../assets/images/google.svg";
export default function Login() {
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const submit = (e) => {
    e.preventDefault();
    const fields = [...e.target];
    console.log("inside submit register: ", fields);
    const user = {
      email: fields[0].value,
      password: fields[1].value,
    };
    login(user)
      .then(() => {
        navigate("/dashboard");
        setMessage(null);
      })
      .catch((err) => {
        console.log("error: ", err.message);
        setMessage("Wrong Email/Password");
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.registerContainer}>
      <Navbar />
      <h1 className={styles.registerTitle}>
        <span className={styles.span}>Welcome Back!</span>
      </h1>
      <hr />
      <LoginForm styles={styles} message={message} submit={submit} />
      <h1>Or</h1>
      <div
        onClick={() => {
          loginWithGoogle()
            .then(function (user) {
              console.log("inside then, should redirect if user");
              if (user) {
                navigate("/dashboard");
              }
            })
            .catch();
        }}
        className={styles.googleSignup}
      >
        <img src={google} />
        <a>SIGN IN WITH GOOGLE</a>
      </div>
      <Footer />
    </div>
  );
}
