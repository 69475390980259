import useUserData from "./useUserData";
import { FirebaseContext } from "./firebaseContext";
import { useContext } from "react";
export default function FirebaseProvider({ children }) {
  const user = useUserData();
  console.log();
  return (
    <FirebaseContext.Provider value={{ user }}>
      {children}
    </FirebaseContext.Provider>
  );
}
export function useAuth() {
  return useContext(FirebaseContext);
}
