import styles from "./../styles/navbar.module.css";
import Hamburger from "hamburger-react";
import { useContext, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { InnerMoon } from "@theme-toggles/react";
import "@theme-toggles/react/css/InnerMoon.css";
import lightLogo from "./../assets/images/lightLogo.png";
import darkLogo from "./../assets/images/darkLogo.png";

import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { ThemeContext } from "../theme/ThemeContext";

import { logout } from "../lib/authFunctions";

import { useAuth } from "../lib/FirebaseProvider";
export default function Navbar() {
  const [hidden, setHidden] = useState(false);
  const [opened, setOpened] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { scrollY } = useScroll();
  const user = useAuth();
  console.log("user from navbar: ", user);
  useMotionValueEvent(scrollY, "change", (latest) => {
    let prev = scrollY.getPrevious();
    console.log("scroll: ", latest, " prev: ", prev);
    if (latest > prev && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });
  const { theme, setTheme } = useContext(ThemeContext);
  console.log("theme is ", theme);
  const [mounted, setMounted] = useState(false);
  let src = lightLogo;
  if (theme == "dark") {
    src = darkLogo;
  } else {
    src = lightLogo;
  }
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }
  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <motion.nav
        variants={{ visible: { y: 0 }, hidden: { y: "-100%" } }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        animate={hidden ? "hidden" : "visible"}
        className={styles.navbarContainer}
      >
        {" "}
        <a href="/" className={styles.navLogoLink}>
          {" "}
          <img
            src={src}
            style={mounted ? { display: "block" } : { display: "none" }}
            alt="iFreeThink Logo"
            className={styles.navLogo}
          />
        </a>
        <div className={styles.themeToggle}>
          <small>Switch Theme to {theme === "dark" ? "Day" : "Night"}</small>
          <InnerMoon
            toggled={toggled}
            duration={1000}
            className={styles.themeToggle}
            toggle={() => {
              if (theme === "dark") {
                setTheme("light");
                setToggled(true);
              } else {
                setTheme("dark");
                setToggled(false);
              }
            }}
          />
          <Hamburger
            toggled={opened}
            size={20}
            toggle={() => {
              setOpened(!opened);
            }}
            color="var(--textcolor)"
          />
        </div>
      </motion.nav>
      {opened ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className={styles.overlay}
          onClick={() => {
            setOpened(false);
          }}
        />
      ) : (
        ""
      )}
      <motion.div
        variants={{
          open: { x: 0, opacity: 1 },
          closed: { x: "-100%", opacity: 0 },
        }}
        initial={{ x: "-200%" }}
        animate={opened ? "open" : "closed"}
        transition={{ duration: 0.2, type: "spring", bounce: 0.5 }}
        onClick={() => {
          setOpened(false);
        }}
        className={opened ? styles.openedNav : styles.closedNav}
      >
        <motion.img
          variants={{
            open: { y: 0, scaleX: 1, rotate: 0 },
            closed: { scaleX: 0.2, y: "-100%", rotate: -5 },
          }}
          animate={opened ? "open" : "closed"}
          transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
          src={darkLogo}
          style={mounted ? { display: "block" } : { display: "none" }}
          alt="iFreeThink Logo"
          width={813}
          height={182}
          className={styles.openLogo}
        />
        <motion.h1
          variants={{
            open: { y: 0 },
            closed: { y: -100 },
          }}
          animate={opened ? "open" : "closed"}
          transition={{ duration: 0.4, delay: 0.4 }}
        >
          Peer To Peer Recovery Technologies
        </motion.h1>
        <ul>
          {user.user !== null ? (
            <motion.li
              variants={{
                open: { y: 0, opacity: 1 },
                closed: { y: -100, opacity: 0 },
              }}
              animate={opened ? "open" : "closed"}
              transition={{ duration: 0.4, delay: 0.6 }}
            >
              <a
                onClick={() => {
                  logout();
                }}
              >
                LOGOUT
              </a>
            </motion.li>
          ) : (
            <motion.li
              variants={{
                open: { y: 0, opacity: 1 },
                closed: { y: -100, opacity: 0 },
              }}
              animate={opened ? "open" : "closed"}
              transition={{ duration: 0.4, delay: 0.6 }}
            >
              <Link to="/login">LOGIN</Link>
            </motion.li>
          )}

          <motion.li
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -100, opacity: 0 },
            }}
            animate={opened ? "open" : "closed"}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            <Link to="/register">REGISTER</Link>
          </motion.li>
          <motion.li
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -100, opacity: 0 },
            }}
            animate={opened ? "open" : "closed"}
            transition={{ duration: 0.4, delay: 0.7 }}
          >
            <HashLink to="/#about">ABOUT</HashLink>
          </motion.li>
          <motion.li
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -100, opacity: 0 },
            }}
            animate={opened ? "open" : "closed"}
            transition={{ duration: 0.4, delay: 0.9 }}
          >
            <HashLink to="/#mission">OUR MISSION</HashLink>
          </motion.li>
          <motion.li
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -100, opacity: 0 },
            }}
            animate={opened ? "open" : "closed"}
            transition={{ duration: 0.4, delay: 1 }}
          >
            <HashLink to="/#contact">CONTACT</HashLink>
          </motion.li>
        </ul>
      </motion.div>
    </div>
  );
}
