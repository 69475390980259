import { useEffect, useState } from "react";

export default function useTyping(words) {
  const [text, setText] = useState(words[0]);

  // const [iterator, setIterator] = useState(0);

  useEffect(() => {
    let paused = false;
    let curWord = "";
    let word = 0;
    let iterator = 0;

    const type = () => {
      if (!paused) {
        let newWord = curWord + words[word].charAt(iterator);

        setText(newWord);
        curWord = newWord;
        iterator++;
      }
      // setIterator(iterator + 1);
      if (iterator > words[word].length) {
        paused = true;

        iterator = 0;
        curWord = "";

        setTimeout(() => {
          paused = false;
        }, 3000);
        if (word < words.length - 1) {
          word++;
        } else {
          word = 0;
        }
      }
    };
    const timer = setInterval(type, 140);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return text;
}
