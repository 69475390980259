import React, { useContext } from "react";
import styles from "./../styles/blandsplash.module.css";

import { useState, useEffect } from "react";

import useTyping from "./../useTyping";
import darkDevices from "./../assets/images/devices.png";
import lightDevices from "./../assets/images/lightDevices.png";
import { motion } from "framer-motion";
import { ThemeContext } from "../theme/ThemeContext";
export default function BlandSplash() {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);
  let heroText = useTyping(["ASSISTANT", "MANAGER", "GUIDE"]);
  let src = darkDevices;
  useEffect(() => {
    setMounted(true);
  });

  if (theme === "dark") {
    src = darkDevices;
  } else {
    src = lightDevices;
  }

  return (
    <div className={styles.blandSplashContainer}>
      {/* <NightSky styles={styles} /> */}
      <div className={styles.textContainer}>
        <motion.h1
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 3, type: "spring", delay: 0.5 }}
          style={{ zIndex: 3 }}
        >
          PEER RECOVERY <br />
        </motion.h1>
        <motion.h1
          initial={{ y: 60, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring", delay: 0.1 }}
          className={styles.threeD}
        >
          {heroText}
        </motion.h1>
        <motion.p
          initial={{ y: 40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 2.8, delay: 0.3, type: "spring" }}
        >
          Making Recovery More Possible Through Technology & Data Analysis
        </motion.p>
      </div>

      <motion.img
        src={theme === "dark" ? darkDevices : lightDevices}
        width={1000}
        height={560}
        initial={{ y: -30, x: 10, opacity: 0 }}
        transition={{ duration: 4, type: "spring", bounce: 0.2 }}
        animate={{ y: 0, x: 0, opacity: 1 }}
        className={styles.heroImage}
      />

      {theme === "dark" && <div className={styles.blob1} />}
      {theme === "dark" && <div className={styles.blob2} />}
    </div>
  );
}
