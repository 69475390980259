import { motion } from "framer-motion";

export default function Item({ styles, obj }) {
  console.log("obj from item : ", obj);

  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: obj.duration, delay: obj.delay, ease: "easeIn" }}
      viewport={{ once: true }}
    >
      <h3>{obj.title}</h3>

      <>
        {" "}
        <hr />
        <p>{obj.paragraph}</p>
      </>
    </motion.div>
  );
}
