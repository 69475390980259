import { db } from "./firebase";
import { collection, addDoc } from "firebase/firestore";
export async function addContact(message) {
  console.log("message from addContact: ", message);
  // return new Promise((res, rej) => {
  //   try {
  //     const dbRef = addDoc(collection(db, "contact"), {
  //       name: message.name,
  //       message: message.message,
  //       email: message.email,
  //       time: new Date().getTime(),
  //     });
  //     res("success");
  //   } catch (e) {
  //     rej(e);
  //     console.log("error adding contact to db: ", e.message);
  //   }
  // });
}
