import styles from "./../styles/page.module.css";
import Lottie from "lottie-react";
import neurons from "./../assets/animations/neurons.json";
import lightNeurons from "./../assets/animations/lightNeurons.json";
import { useContext, useRef } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../theme/ThemeContext";
export default function NeuronsContainer() {
  const neuronsRef = useRef();
  const { theme, setTheme } = useContext(ThemeContext);
  const interactivity = {
    mode: "scroll",
    actions: [
      {
        visibility: [0.2, 1.0],
        type: "play",
      },
    ],
  };
  let src;
  if (theme === "dark") {
    src = neurons;
  } else {
    src = lightNeurons;
  }

  return (
    <div className={styles.neuronsContainer}>
      <Lottie
        animationData={src}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        loop={false}
        lottieRef={neuronsRef}
        autoplay={true}
        interactivity={interactivity}
        className={styles.neuronSVG}
      />
      <div className={styles.textContainer}>
        {" "}
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.1, easing: "easeIn" }}
          viewport={{ once: true }}
          className={styles.splashText}
        >
          <h1>
            <span>RECOVERY</span> CONNECTIONS
          </h1>
          <hr />
          <p>
            From Recovery Coaches to AA Meetings, Job Interviews and Housing
            Applications, I Free Think makes the connections for you!
          </p>
        </motion.div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2, easing: "easeIn" }}
          viewport={{ once: true }}
          className={styles.list}
        >
          <div className={styles.listItem}>
            <h1>Peer To Peer</h1>
          </div>
          <div className={styles.listItem}>
            <h1>Recovery Calendar</h1>
          </div>
          <div className={styles.listItem}>
            <h1>Job Search</h1>
          </div>
          <div className={styles.listItem}>
            <h1>Recovery Resources</h1>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
