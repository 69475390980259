import { useContext, useEffect, useState } from "react";

import useUserData from "../lib/useUserData";
import { FirebaseContext } from "../lib/firebaseContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../lib/FirebaseProvider";

export default function AuthCheck({ children }) {
  const user = useAuth();

  const navigate = useNavigate();
  if (!user.user) {
    console.log("no user, redirecting");
    navigate("/login");
  } else {
    console.log("user from authcheck :", user.user.uid);
  }
  return (
    <>
      {user.user !== "loading" && user.user !== null ? (
        <>{children}</>
      ) : (
        <h1>It Looks Like You Are Not Logged In...</h1>
      )}
    </>
  );
}
