import React from "react";
import styles from "./../styles/cta.module.css";

export default function Cta() {
  return (
    <div className={styles.ctaContainer}>
      <div className={styles.overlay} />

      <h1>
        Engineered <span>Recovery</span> Solutions
      </h1>
      <hr />
      <p>
        iFreeThink is dedicated to solving age old recovery problems, from
        finding work to getting involved in your community to seeking meetings
        and sponsors.
      </p>
      <a className={styles.ctaButton} href="/more">
        Read More
      </a>
    </div>
  );
}
