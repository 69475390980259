// import "./global.css";
import { useContext } from "react";
import { ThemeContext } from "./theme/ThemeContext";

import styles from "./styles/page.module.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

function App() {
  const { theme, setTheme } = useContext(ThemeContext);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    { path: "/register", element: <Register /> },
    { path: "/login", element: <Login /> },
    { path: "/dashboard", element: <Dashboard /> },
  ]);
  return (
    <div className="app" data-theme={theme}>
      <div className={styles.siteOverlay} />

      <RouterProvider router={router} />
    </div>
  );
}

export default App;
