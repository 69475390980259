import React from "react";
import { useState, useEffect } from "react";
import styles from "./../styles/drip.module.css";
export default function Drip() {
  const [rand1, setRand1] = useState(270);
  const [rand2, setRand2] = useState(600);
  const [rand3, setRand3] = useState(900);
  const [rand4, setRand4] = useState(270);
  const [rand5, setRand5] = useState(600);
  const [rand6, setRand6] = useState(900);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);

  useEffect(() => {
    let dripper;
    let dripper1 = document.querySelector("#drip1");
    let dripper2 = document.querySelector("#drip2");
    let dripper3 = document.querySelector("#drip3");
    let dripper4 = document.querySelector("#drip4");
    let dripper5 = document.querySelector("#drip5");
    let dripper6 = document.querySelector("#drip6");
    let active = 1;
    let last = 0;
    const sendDrip = () => {
      last++;
      if (last % 2 == 0) {
        return;
      }
      switch (active) {
        case 1:
          dripper = dripper1;

          setActive1(true);
          active = 2;
          break;
        case 2:
          setActive2(true);
          active = 3;
          dripper = dripper2;
          break;
        case 3:
          setActive3(true);
          active = 4;
          dripper = dripper3;
          break;
        case 4:
          setActive4(true);
          active = 5;
          dripper = dripper4;
          break;
        case 5:
          setActive5(true);
          active = 6;
          dripper = dripper5;
          break;
        case 6:
          setActive6(true);
          active = 1;
          dripper = dripper6;
          break;
        default:
          break;
      }

      let dur = Math.random() * 7 + 3;

      let r = Math.floor(Math.random() * 40);

      dripper1.addEventListener(
        "animationend",
        function oneListener() {
          setActive1(false);
          setRand1(r * 30);

          dripper1.removeEventListener("animationend", oneListener);
          dripper1.style.setProperty("animation-duration", dur + "s");
        },
        false
      );

      dripper2.addEventListener(
        "animationend",
        function twoListener() {
          setActive2(false);
          setRand2(r * 60);

          dripper2.removeEventListener("animationend", twoListener);
          dripper2.style.setProperty("animation-duration", dur + "s");
        },
        false
      );

      dripper3.addEventListener(
        "animationend",
        function threeListener() {
          setActive3(false);
          setRand3(r * 30);
          dripper3.removeEventListener("animationend", threeListener);
          dripper3.style.setProperty("animation-duration", dur + "s");
        },
        false
      );
      dripper4.addEventListener(
        "animationend",
        function threeListener() {
          setActive4(false);
          setRand4(r * 30);
          dripper4.removeEventListener("animationend", threeListener);
          dripper4.style.setProperty("animation-duration", dur + "s");
        },
        false
      );
      dripper5.addEventListener(
        "animationend",
        function threeListener() {
          setActive5(false);
          setRand5(r * 30);
          dripper5.removeEventListener("animationend", threeListener);
          dripper5.style.setProperty("animation-duration", dur + "s");
        },
        false
      );
      dripper6.addEventListener(
        "animationend",
        function threeListener() {
          setActive6(false);
          setRand6(r * 30);
          dripper6.removeEventListener("animationend", threeListener);
          dripper6.style.setProperty("animation-duration", dur + "s");
        },
        false
      );
    };

    const dripTimer = setInterval(sendDrip, 1000);
    return () => {
      clearInterval(dripTimer);
    };
  }, []);

  return (
    <div className={styles.dripContainer}>
      {" "}
      <div
        id="drip1"
        className={active1 ? styles.drip : styles.noDrip}
        style={{
          left: rand1,
        }}
      />
      <div
        id="drip2"
        className={active2 ? styles.drip : styles.noDrip}
        style={{
          left: rand2,
        }}
      />
      <div
        id="drip3"
        className={active3 ? styles.drip : styles.noDrip}
        style={{
          left: rand3,
        }}
      />
      <div
        id="drip4"
        className={active4 ? styles.drip : styles.noDrip}
        style={{
          left: rand4,
        }}
      />
      <div
        id="drip5"
        className={active5 ? styles.drip : styles.noDrip}
        style={{
          left: rand5,
        }}
      />
      <div
        id="drip6"
        className={active6 ? styles.drip : styles.noDrip}
        style={{
          left: rand6,
        }}
      />
    </div>
  );
}
