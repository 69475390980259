import { useContext, useEffect } from "react";
import { ThemeContext } from "../theme/ThemeContext";
import styles from "./../styles/page.module.css";
import Drip from "../components/Drip";
import BlandSplash from "../components/BlandSplash";
import Hor from "../components/Hor";
import WhatWill from "../components/WhatWill";
import NeuronsContainer from "../components/NeuronsContainer";
import Cta from "../components/Cta";
import SolveNow from "../components/SolveNow";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contains from "../components/containsComponent/Contains";
import MockMentors from "../components/MockMentors";

export default function Home() {
  const { theme, setTheme } = useContext(ThemeContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Drip />
      <Navbar />
      <BlandSplash />
      <Contains />
      <WhatWill />
      <NeuronsContainer />
      <MockMentors />
      <Cta />
      <SolveNow />
      <Contact />
      <Footer />
    </div>
  );
}
