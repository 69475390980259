import { useAuth } from "../../lib/FirebaseProvider";

export default function ClientHome({ styles }) {
  const user = useAuth();
  console.log("user from clientHome: ", user.user.emailVerified);
  return (
    <div className={styles.clientHome}>
      <h1>Client Dashboard</h1>
      {!user.user.emailVerified ? (
        <p>Your Email Is Not Verified</p>
      ) : (
        <p>All Verified!</p>
      )}
    </div>
  );
}
