import { useState } from "react";
import * as validator from "email-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
export default function RegisterMentee({ styles, submit, message }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState({
    email: { filled: false, error: false, errorMessage: "" },
    password: { filled: false, error: false, errorMessage: "" },
    confirmPassword: { filled: false, error: false, errorMessage: "" },
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const validCheck = () => {
    if (email !== "" && password !== "" && confirmPassword !== "") {
      //filled em all
      if (
        !errors.email.error &&
        !errors.password.error &&
        !errors.confirmPassword.error
      ) {
        console.log("valid form");
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    } else {
      setFormValid(false);
    }
  };
  const onPasswordChange = (field, value) => {
    let errObj = errors;
    switch (field) {
      case "password":
        if (value.length >= 8) {
          console.log("valid password length");
          errObj.password.error = false;
          errObj.password.errorMessage = "";
          errObj.password.filled = true;
        } else {
          console.log("password too short");
          errObj.password.error = true;
          errObj.password.errorMessage = "Password Too Short";
          errObj.filled = false;
        }
        if (value !== confirmPassword && confirmPassword !== "") {
          errObj.confirmPassword.error = true;
          errObj.confirmPassword.errorMessage = "Confirm Password Must Match";
        } else {
          errObj.confirmPassword.error = false;
          errObj.confirmPassword.errorMessage = "";
        }
        setErrors(errObj);
        setPassword(value);
        break;
      case "confirmPassword":
        if (value.length >= 8 && value == password) {
          console.log("valid confirmPassword");
          errObj.confirmPassword.error = false;
          errObj.confirmPassword.errorMessage = "";
          errObj.confirmPassword.filled = true;
        } else {
          errObj.confirmPassword.error = true;
          errObj.confirmPassword.errorMessage = "Confirm Password Must Match";
          errObj.confirmPassword.filled = false;
        }
        setErrors(errObj);
        setConfirmPassword(value);
        break;
      default:
        break;
    }
    validCheck();
  };
  const onFieldChange = (field, value) => {
    let errObj = errors;
    switch (field) {
      case "email":
        if (!validator.validate(value)) {
          errObj.email.error = true;
          errObj.email.errorMessage = "You Must Provide A Valid Email";
        } else {
          errObj.email.error = false;
          errObj.email.errorMessage = "";
        }
        setErrors(errObj);
        setEmail(value);
        break;

      default:
        break;
    }
    validCheck();
  };
  return (
    <form className={styles.registerForm} onSubmit={submit}>
      <h1>SIGN UP WITH EMAIL</h1>

      <div className={styles.inputRow}>
        <div className={styles.inputSVGContainer}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>

        <input
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          className={errors.email.error ? styles.err : ""}
          onChange={(e) => {
            onFieldChange("email", e.target.value);
          }}
        />
      </div>

      {errors.email.error ? (
        <p className={styles.errorMessage}>{errors.email.errorMessage}</p>
      ) : (
        ""
      )}
      <div className={styles.inputRow}>
        {" "}
        <div className={styles.inputSVGContainer}>
          <FontAwesomeIcon icon={faLock} />
        </div>
        <input
          placeholder="Password"
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            onPasswordChange("password", e.target.value);
          }}
        />
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputSVGContainer}>
          <FontAwesomeIcon icon={faLock} />
        </div>
        <input
          placeholder="Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => {
            onPasswordChange("confirmPassword", e.target.value);
          }}
        />
      </div>

      {errors.password.error ? (
        <p className={styles.errorMessage}>{errors.password.errorMessage}</p>
      ) : (
        ""
      )}
      {errors.confirmPassword.error ? (
        <p className={styles.errorMessage}>
          {errors.confirmPassword.errorMessage}
        </p>
      ) : (
        ""
      )}
      {message && <p className={styles.errorMessage}>{message}</p>}
      <button
        disabled={formValid ? false : true}
        className={formValid ? styles.submit : styles.disabled}
        type="submit"
      >
        SUBMIT
      </button>
    </form>
  );
}
