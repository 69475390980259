import { auth, firestore, googleProvider } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
export async function register(fields) {
  console.log("inside register function: ", fields);
  return new Promise((res, rej) => {
    console.log("auth is: ", auth);
    createUserWithEmailAndPassword(auth, fields.email, fields.password)
      .then((userCredential) => {
        // Signed up
        console.log("register success :", userCredential);
        const user = userCredential.user;
        sendEmailVerification(user).then((resp) => {
          res(resp);
        });

        // ...
      })
      .catch((error) => {
        console.log("register error : ", error.message);
        const errorCode = error.code;
        const errorMessage = error.message;
        rej(error);
        // ..
      });
  });
}

export function logout() {
  console.log("user is: ", auth.currentUser);
  signOut(auth).then(() => {
    console.log("signed out");
  });
}
export async function login(fields) {
  console.log("user is: ", auth.currentUser);
  return new Promise((res, rej) => {
    signInWithEmailAndPassword(auth, fields.email, fields.password)
      .then(() => {
        res("success");
      })
      .catch((err) => {
        console.log("err: ", err);
        rej("err");
      });
  });
}

export async function loginWithGoogle() {
  return new Promise((res, rej) => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);

        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        res(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        rej(error);
      });
  });
}
