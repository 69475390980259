import { useState, useEffect } from "react";
import styles from "./../styles/register.module.css";
import google from "./../assets/images/google.svg";
import SelectAccountType from "../components/register/SelectAccountType";
import RegisterMentee from "../components/register/RegisterMentee";
import { loginWithGoogle, register } from "../lib/authFunctions";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Register() {
  const [accountType, setAccountType] = useState(null);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const submit = (e) => {
    e.preventDefault();
    const fields = [...e.target];
    console.log("inside submit register: ", fields);
    const user = {
      email: fields[0].value,
      password: fields[1].value,
    };
    register(user)
      .then(() => {
        navigate("/dashboard");
      })
      .catch((err) => {
        setMessage("Error: ", err.message);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.registerContainer}>
      <Navbar />
      <h1 className={styles.registerTitle}>
        Join <span className={styles.span}>iFreeThink</span> Today
        <br />
        <small> and take charge of your Recovery Journey!</small>
      </h1>
      <hr />
      <RegisterMentee styles={styles} message={message} submit={submit} />

      <h1>Or</h1>
      <div
        onClick={() => {
          loginWithGoogle()
            .then(function (user) {
              console.log("inside then, should redirect if user");
              if (user) {
                navigate("/dashboard");
              }
            })
            .catch();
        }}
        className={styles.googleSignup}
      >
        <img src={google} />
        <a>SIGN UP WITH GOOGLE</a>
      </div>
      <div className={styles.blob} />
      <Footer />
    </div>
  );
}
