import AuthCheck from "../components/AuthCheck";
import ClientDashboard from "../components/dashboard/ClientDashboard";
import styles from "./../styles/dashboard.module.css";
export default function Dahsboard() {
  return (
    <div className={styles.dashboardContainer}>
      <AuthCheck>
        <ClientDashboard />
      </AuthCheck>
    </div>
  );
}
